import React from 'react';
import moment from 'moment';
import 'moment/locale/sl';
import styles from './Meta.module.scss';

moment.locale('sl');

const Meta = ({ date }) => (
  <div className={styles['meta']}>
    <p className={styles['meta__date']}>Izdana {moment(date).format('D MMM YYYY')}</p>
  </div>
);

export default Meta;
