import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Post from '../components/Post';

const PostTemplate = ({ data }) => {
  const {
    title: siteTitle,
    subtitle: siteSubtitle
  } = data.site.siteMetadata;

  const {
    title: postTitle,
  } = data.contentfulBlogPost;

  const {
    description: postDescription
  } = data.contentfulBlogPost.description

  const metaDescription = postDescription !== null ? postDescription : siteSubtitle;

  return (
    <Layout title={`${postTitle} - ${siteTitle}`} description={metaDescription}>
      <Post post={data.contentfulBlogPost} />
    </Layout>
  );
};

export const query = graphql`
  query PostBySlug($slug: String!) {
    site {
      siteMetadata {
        author {
          name
          contacts {
            twitter
          }
        }
        disqusShortname
        subtitle
        title
        url
      }
    }
    contentfulBlogPost(slug: {eq: $slug}) {
      id
      publishDate
      tags
      title
      fields{
        tagSlugs
      }
      description{
        description
      }
      body {
        childMarkdownRemark{
          html
        }
      }
    }
  }
`;


// export const query = graphql`
//   query PostBySlug($slug: String!) {
//     site {
//       siteMetadata {
//         author {
//           name
//           contacts {
//             twitter
//           }
//         }
//         disqusShortname
//         subtitle
//         title
//         url
//       }
//     }
//     markdownRemark(fields: { slug: { eq: $slug } }) {
//       id
//       html
//       fields {
//         tagSlugs
//       }
//       frontmatter {
//         date
//         description
//         tags
//         title
//       }
//     }
//   }
// `;

export default PostTemplate;
